import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "text-input",
    ref: "input",
    contenteditable: true,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
    onPaste: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.paste && _ctx.paste(...args))),
    onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
    onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
    onKeypress: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.keyPress && _ctx.keyPress(...args))),
    onKeydown: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.keyDown && _ctx.keyDown(...args))),
    onCompositionstart: _cache[6] || (_cache[6] = ($event: any) => (_ctx.composition = true)),
    onCompositionend: _cache[7] || (_cache[7] = ($event: any) => (_ctx.composition = false))
  }, null, 544))
}