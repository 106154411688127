import {
  DatabaseUserData,
  UserCommentData,
  UserData,
  UserScheduleData,
  UserStatusData,
} from "@/data/interface";
import { basicUsers } from "@/data/initial-data";
import { database } from "@/firebase";
import { ref as docRef, get, onChildChanged } from "firebase/database";
import { ref } from "vue";

const sheetId = "1N_-QadTzo6Fm7553Mz7-O_Om1R9B9iG6sYAspO_a3cQ";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const userDataRepository = () => {
  const originalUsers = ref<UserData[]>(JSON.parse(JSON.stringify(basicUsers)));
  const users = ref<UserData[]>(JSON.parse(JSON.stringify(basicUsers)));

  const r = docRef(database, sheetId + "/data");
  let firstLoad = true;
  let initialCount = 0;

  const updateData = (d: DatabaseUserData) => {
    const comment: UserCommentData | null =
      d.comment.trim() === ""
        ? null
        : {
            text: d.comment,
            createdAt: new Date().toString(),
          };
    const status: UserStatusData | null =
      d.status.trim() === "" || d.status.trim() === "×"
        ? null
        : {
            emoji: d.status,
            description: getDescription(d.status),
            expiredAt: new Date().toString(),
          };
    const schedule: UserScheduleData | null =
      d.schedule.trim() === ""
        ? null
        : {
            displayableSummary: d.schedule,
            startAt: new Date().toString(),
            endAt: new Date().toString(),
          };
    for (const u of originalUsers.value) {
      if (u.id === d.id) {
        u.displayName = d.displayName;
        u.comment = comment;
        u.status = status;
        u.schedule = schedule;
        break;
      }
    }
    for (const u of users.value) {
      if (u.id === d.id) {
        u.displayName = d.displayName;
        if (firstLoad) {
          if (Math.random() < 0.1 && initialCount < 5) {
            u.comment = comment;
            initialCount++;
          }
        } else {
          u.comment = comment;
        }
        u.status = status;
        u.schedule = schedule;
        return;
      }
    }
  };

  (async () => {
    onChildChanged(r, (s) => {
      updateData(s.toJSON() as DatabaseUserData);
    });

    const d = await get(r);
    const dbdata: { [key: string]: DatabaseUserData } = d.toJSON() as {
      [key: string]: DatabaseUserData;
    };

    for (const prop in dbdata) {
      const d = dbdata[prop];
      updateData(d);
    }

    main: for (const u of users.value) {
      for (const d of originalUsers.value) {
        if (u.id === d.id) {
          // const duration = Math.random() * 5 * 60 * 1000;
          const duration = 1000;
          setTimeout(() => {
            updateUser(u, d);
          }, duration);
          continue main;
        }
      }
    }

    firstLoad = false;
  })();

  return {
    users,
  };
};

const getDescription = (emoji: string) => {
  switch (emoji) {
    case "🏢":
      return "出社";
    case "🏠":
      return "在宅勤務";
    case "📅":
      return "予定あり";
    case "👩‍👦‍👦":
      return "MTG";
    case "☕️":
      return "離席";
    case "🍔":
      return "食事中";
    case "🚗":
      return "移動";
  }
  return "";
};

const updateUser = (u: UserData, d: UserData) => {
  let duration = 0;
  if (!u.comment) {
    u.comment = d.comment;
    duration = Math.random() * 5 * 1000 + 5 * 1000;
  } else {
    u.comment = null;
    duration = Math.random() * 5 * 1000 + 5 * 1000;
  }
  setTimeout(() => {
    updateUser(u, d);
  }, duration);
};
