
import {
  computed,
  defineComponent,
  inject,
  nextTick,
  onMounted,
  ref,
  watch,
} from "vue";
import MultilineInput from "@/components/user/multiline-input.vue";
import { UserData, UserDataKey } from "./user-data";

export default defineComponent({
  name: "Comment",
  components: {
    MultilineInput,
  },
  props: {
    active: Boolean,
  },
  setup(props) {
    const { me, data, updateComment } = inject(UserDataKey) as UserData;
    const message = ref<HTMLElement>();
    const messageInput = ref<InstanceType<typeof MultilineInput>>();
    const inputText = ref("");
    const commentData = ref(data.value.comment);
    const editMode = ref(false);
    const commentHeight = ref(0);

    const oneLineHeight = 17.5;
    const containerStyle = computed(() => {
      updateCommentHeight();
      return {
        height: commentHeight.value + "px",
      };
    });

    const updateCommentHeight = () => {
      if (message.value) {
        const r = message.value.getBoundingClientRect();
        if (props.active) {
          commentHeight.value = r.height;
        } else {
          commentHeight.value = Math.min(
            Math.max(r.height, oneLineHeight),
            oneLineHeight * 2.2
          );
        }
      }
    };

    const displayText = computed(() => {
      return commentData.value ? commentData.value.text : "";
    });

    watch(
      () => data.value.comment,
      () => {
        commentData.value = data.value.comment;
        setTimeout(updateCommentHeight);
      }
    );

    const edit = () => {
      if (!me.value) return;
      inputText.value = commentData.value ? commentData.value.text : "";
      editMode.value = true;
      nextTick(() => {
        if (messageInput.value) messageInput.value.$el.focus();
      });
    };

    const applyText = () => {
      editMode.value = false;
      updateComment(inputText.value.trim());
    };

    onMounted(() => {
      updateCommentHeight();
    });

    return {
      me,
      commentData,
      inputText,
      message,
      messageInput,
      editMode,
      containerStyle,
      displayText,
      applyText,
      edit,
    };
  },
});
