
import { computed, defineComponent, inject, ref, watch } from "vue";
import { UserData, UserDataKey } from "./user-data";

export default defineComponent({
  name: "NextSchedule",
  props: {
    active: Boolean,
  },
  setup() {
    const { data } = inject(UserDataKey) as UserData;
    const scheduleData = ref(data.value.schedule);
    const summery = computed(() => {
      if (!scheduleData.value) return "";
      return scheduleData.value.displayableSummary
        ? scheduleData.value.displayableSummary
        : "予定あり";
    });

    watch(
      () => data.value.schedule,
      () => {
        scheduleData.value = data.value.schedule;
      }
    );

    return {
      scheduleData,
      summery,
    };
  },
});
