import { UserData } from "@/data/interface";

export const basicUsers: UserData[] = [
  {
    id: 2,
    displayName: "shimomura",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GjoVTPsDeUYN8BlTF-Fn-H0zfxqvHc3aTeNjs5KIg=s100",
  },
  {
    id: 3,
    displayName: "hatta",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GjUNpobqBq1yCwBCKSSnzS0FPIdj-NgEiLy-v4QNQ=s100",
  },
  {
    id: 4,
    displayName: "kageyama",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GjbrhcGHMZm7OOt2I6dn5Wl0pvoULb9XQ4FTlBFOuo=s100",
  },
  {
    id: 5,
    displayName: "miyanami",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GgxXzMwDrf9EmACFHyaRsHl7nuQx_pLwUYQ1pKyyw=s100",
  },
  {
    id: 6,
    displayName: "idogawa",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GiFXFrdrS4843LqdlcJxQGZOlGVd7XXS6rhx7kK3A=s100",
  },
  {
    id: 8,
    displayName: "takahashi",
    profileImage:
      "https://lh3.googleusercontent.com/a/AATXAJzwTJFrvILmLkJU3HiooOz7s62uIEtOrCNn_Fsu=s100",
  },
  {
    id: 9,
    displayName: "hidaka",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GhMAKAD3EwSKPGrueoH03im66oXg0y2eo46Zdym=s100",
  },
  {
    id: 10,
    displayName: "ojima",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GjC_ZAT8CQd-dMD03MOiDS_dDSWOj2pVwKLWjW5Jw=s100",
  },
  {
    id: 11,
    displayName: "tsurumoto",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GhZxiatfrSV9veHYBBtDIyQHK52rachJ3yqm92Mzw=s100",
  },
  {
    id: 12,
    displayName: "ohtsuka",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GgWSk_H_uaCoDH1idjyhbKoJenmACeArkkszzT5pg=s100",
  },
  {
    id: 13,
    displayName: "katayama",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14Gji7bwFuJ6mnqgmubRChN1A-moO-OvMt6BPd_4fPg=s100",
  },
  {
    id: 14,
    displayName: "ebisawa",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GgV5ErC8D9vH0h9jZyCIdES1zUDstbxTvo6D0DbZQ=s100",
  },
  {
    id: 15,
    displayName: "sasaki",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GgCSazdjjucYiMW1TyE8Q1B6Y-0AQGf0qCf9z3Z6Q=s100",
  },
  // {
  //   id: 16,
  //   displayName: "fukuda",
  //   profileImage:
  //     "https://lh3.googleusercontent.com/a-/AOh14GgNtymqjB7mFVeQwwL56rMmLHz7fPCCMy78rNQLxw=s100",
  // },
  // {
  //   id: 17,
  //   displayName: "araki",
  //   profileImage:
  //     "https://lh3.googleusercontent.com/a-/AOh14GjucnEyu9IeaNhdOwjH3It_bzWybbNNdCCHNWkVyg=s100",
  // },
  // {
  //   id: 18,
  //   displayName: "soumi",
  //   profileImage:
  //     "https://lh3.googleusercontent.com/a-/AOh14Ggqtsmn1PexnBIDIfA18KY8WnRu29bFPHAxsAd1ZQ=s100",
  // },
  {
    id: 19,
    displayName: "t.kubota",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GjB6E_CizY0m6NP6elcDcS2FdnMXJIEfThMBFQz=s100",
  },
  {
    id: 20,
    displayName: "yoshioka",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14Gjt5MY_15NgEQHc6doyZw_w6mJm28d1oqSP3DmP=s100",
  },
  // {
  //   id: 22,
  //   displayName: "nonoyama",
  //   profileImage:
  //     "https://lh3.googleusercontent.com/a-/AOh14Gj6tK6GGwtkjgFYH-wrAs_XO6cu4gmc6_jYoPU-ig=s100",
  // },
  {
    id: 24,
    displayName: "shinpo",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GjOT9dun-pDr0JhO1lYHXRHuBQtx1sxn_TTy2YV8Q=s100",
  },
  {
    id: 25,
    displayName: "seino",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GiKiRaqvpbcDn4Zq-8E4i8j0NPR-RrxJzPtEoYeHg=s100",
  },
  {
    id: 26,
    displayName: "y.kawase",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GgkB2PWktxmiHBGFTbItD6Repc7bRgL0pK128yvqQ=s100",
  },
  {
    id: 27,
    displayName: "m.kawase",
    profileImage:
      "https://lh3.googleusercontent.com/a/AATXAJxmqnHmxdJHz81zauZJwcLfLvL3lABgvDj4zoIy=s100",
  },
  {
    id: 30,
    displayName: "maekawa",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GgfuQo6Cj_9QGX3xXXIExvlAxvC3Spao6TJ0dIy=s100",
  },
  {
    id: 35,
    displayName: "honda",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14Gg2Qr8V83QFyS3GuQoSYgM2zWGcN9S4npktIm52CQ=s100",
  },
  {
    id: 36,
    displayName: "kobayashi",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14Gi96QAq-8PgKCQZod_u9ZK0HgWbIU7MYHsv9XVAKA=s100",
  },
  {
    id: 37,
    displayName: "suzuki",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GglbmbcfSRBgHgMaR6vs2uOyN7wEdgM3LwzW2BT2Q=s100",
  },
  {
    id: 40,
    displayName: "kunimoto",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GjR0xb1C5h_eisUsnVu5W7p1j6myVXeXuHUN6ntsg=s100",
  },
  {
    id: 44,
    displayName: "fujinaka",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14Gge52Ke9tLiwKBF1u4jisWKMkH4SKe-nwm2t4SdUw=s100",
  },
  {
    id: 47,
    displayName: "a.kubota",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14Gh2fsg8MiDI8Q7g-Qp-Agcy8M2_m1RURCqrHztkDQ=s100",
  },
  {
    id: 50,
    displayName: "kimpara",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GjFXCp8Zf0A_y2NFbd6BK6FLJgGIff1NLYV-bU2GQ=s100",
  },
  {
    id: 53,
    displayName: "sannomiya",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GjQW5-MoiL_4GfH_jw5bkTxaVrnpo6HiFLXYpH0IQ=s100",
  },
  {
    id: 54,
    displayName: "natsukawa",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14Gh0cfdhKJqg8zDAZo2IiLj4bx48wyiUvfWu49Htjg=s100",
  },
  {
    id: 55,
    displayName: "marcel",
    profileImage:
      "https://lh3.googleusercontent.com/a-/AOh14GhMg8FMfizNQ5bUu16LxV-pmOag2dq1M9h0TZXxaA=s100",
  },
  // {
  //   id: 58,
  //   displayName: "miyazaki",
  //   profileImage:
  //     "https://lh3.googleusercontent.com/a-/AOh14GhO5H0HEMr-peQMzzfTtynD65BRl3ZJhPG2pRQfIA=s100",
  // },
  {
    id: 59,
    displayName: "nishikawa",
    profileImage:
      "https://lh3.googleusercontent.com/a/ACg8ocI3ZxUYaadA6IvPxqd85j173C8C5GGXTF4S-P_75hobxxk=s100",
  },
];

export const you: UserData = {
  id: 99,
  displayName: "SD君",
  profileImage: `/img/you${Math.ceil(Math.random() * 3)}.png`,
  status: {
    emoji: "📅",
    description: "予定あり",
    expiredAt: "2022-01-01",
  },
  comment: {
    text: "Googleカレンダーと連携できる",
    createdAt: "2022-01-01",
  },
};
