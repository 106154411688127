import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ae748e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-palette" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_User = _resolveComponent("User")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (u) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "user-unit",
        key: u.id
      }, [
        _createVNode(_component_User, { data: u }, null, 8, ["data"])
      ]))
    }), 128))
  ]))
}