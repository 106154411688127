
import { defineComponent, inject, ref } from "vue";
import { UserData, UserDataKey } from "./user-data";

interface Status {
  emoji: string;
  label: string;
}

export default defineComponent({
  name: "StatusPalette",
  props: {
    active: Boolean,
  },
  emits: {
    select: null,
  },
  setup(props, ctx) {
    const { me, updateStatus, deleteStatus } = inject(UserDataKey) as UserData;
    const statusList = ref<Status[]>([
      {
        emoji: "🏢",
        label: "出社",
      },
      {
        emoji: "🏠",
        label: "在宅勤務",
      },
      {
        emoji: "📅",
        label: "予定あり",
      },
      {
        emoji: "👩‍👦‍👦",
        label: "MTG",
      },
      {
        emoji: "☕️",
        label: "離席",
      },
      {
        emoji: "🍔",
        label: "食事中",
      },
      {
        emoji: "🚗",
        label: "移動",
      },
    ]);

    const select = (s: Status | null) => {
      if (s) {
        updateStatus(s.emoji, s.label);
      } else {
        deleteStatus();
      }
      ctx.emit("select");
    };

    return {
      me,
      statusList,
      select,
    };
  },
});
