import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d95efea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "status-badge"
}
const _hoisted_2 = { class: "emoji" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.statusData || _ctx.me)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["status-container", { offset: !_ctx.active }])
        }, [
          (!_ctx.statusData && _ctx.me)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["status empty", { offset: !_ctx.active }])
              }, "＋", 2))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["status", { animation: !_ctx.me, offset: !_ctx.active }])
              }, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.statusData.emoji), 1),
                _createElementVNode("div", {
                  class: "message-container",
                  style: _normalizeStyle(_ctx.containerStyle)
                }, [
                  _createElementVNode("div", {
                    class: "message",
                    ref: "message"
                  }, _toDisplayString(_ctx.statusData.description), 513)
                ], 4)
              ], 2))
        ], 2)
      ]))
    : _createCommentVNode("", true)
}