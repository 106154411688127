
import { UserData as RawUserData } from "@/data/interface";
import { computed, defineComponent, PropType, provide, ref } from "vue";
import Comment from "@/components/user/comment.vue";
import StatusBadge from "@/components/user/status-badge.vue";
import NextSchedule from "@/components/user/next-schedule.vue";
import StatusPalette from "@/components/user/status-palette.vue";
import { userData, UserDataKey } from "@/components/user/user-data";
import { breakPoint } from "@/vars";

export default defineComponent({
  name: "User",
  components: {
    Comment,
    StatusBadge,
    NextSchedule,
    StatusPalette,
  },
  props: {
    data: {
      type: Object as PropType<RawUserData>,
      required: true,
    },
  },
  setup(props) {
    const provided = userData(props.data);
    provide(UserDataKey, provided);

    const over = ref(false);
    const root = ref<HTMLElement>();
    const palette = ref<InstanceType<typeof StatusPalette>>();
    const showStatusPalette = ref(false);

    const active = computed(() => over.value || showStatusPalette.value);
    const me = computed(() => provided.me.value);

    const openPelette = () => {
      if (!me.value) return;
      if (showStatusPalette.value) return;
      showStatusPalette.value = true;
      setTimeout(() => {
        window.addEventListener("click", checkClosePalette);
      });
    };

    const closePalette = () => {
      showStatusPalette.value = false;
      window.removeEventListener("click", checkClosePalette);
      window.addEventListener("mousemove", checkPosition);
    };

    const checkPosition = (e: MouseEvent) => {
      const el = e.target as HTMLElement;
      if (root.value && !root.value.contains(el)) {
        over.value = false;
      }
      if (!over.value) {
        window.removeEventListener("mousemove", checkPosition);
        window.removeEventListener("click", checkPosition);
      }
    };

    const checkClosePalette = (e: MouseEvent) => {
      const el = e.target as HTMLElement;
      if (palette.value) {
        if (!palette.value.$el.contains(el)) {
          closePalette();
        }
      }
    };

    const checkActive = () => {
      if (window.innerWidth < breakPoint) return;
      over.value = true;
    };

    const checkInactive = () => {
      if (window.innerWidth < breakPoint) return;
      over.value = false;
    };

    const checkClickActive = () => {
      if (window.innerWidth >= breakPoint) return;
      over.value = true;
      window.addEventListener("click", checkPosition);
    };

    return {
      over,
      root,
      palette,
      active,
      showStatusPalette,
      me,
      openPelette,
      closePalette,
      checkActive,
      checkClickActive,
      checkInactive,
    };
  },
});
