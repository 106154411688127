
import { defineComponent } from "vue";
import { you } from "@/data/initial-data";
import User from "@/components/user.vue";

export default defineComponent({
  name: "Description",
  components: {
    User,
  },
  setup() {
    return { you };
  },
});
