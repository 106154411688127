
import { defineComponent } from "vue";
import Activities from "@/components/activities.vue";

export default defineComponent({
  name: "App",
  components: {
    Activities,
  },
});
