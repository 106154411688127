
import { defineComponent, onMounted, ref, watch } from "@vue/runtime-core";

export default defineComponent({
  name: "MultilineInput",
  props: {
    modelValue: String,
    singleline: Boolean,
  },
  emits: {
    "update:modelValue": null,
    enter: null,
    escape: null,
    focus: null,
    blur: null,
  },
  setup(props, ctx) {
    const input = ref<HTMLElement>();
    const focusIn = ref<boolean>();
    const composition = ref(false);

    const updateValue = () => {
      if (!focusIn.value) {
        let t = "";
        if (props.modelValue) {
          const elem: string[] = props.modelValue.split("\n");
          for (const e of elem) {
            t += "<div>" + (e !== "" ? e : "<br>") + "</div>";
          }
        }
        if (input.value) input.value.innerHTML = t;
      }
    };

    watch(() => props.modelValue, updateValue);

    const update = (e: Event) => {
      const target: HTMLElement = e.target as HTMLElement;
      let breakdown = target.innerHTML
        .replaceAll("<div><br></div>", "\n")
        .replaceAll("<div>", "\n")
        .replaceAll("</div>", "")
        .replaceAll("<br>", "\n")
        .replaceAll(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
        .replaceAll(/&(.+?);/g, "");
      if (target.innerHTML === "<br>") {
        if (input.value) input.value.innerText = "";
      }
      ctx.emit("update:modelValue", breakdown);
    };

    const paste = (e: ClipboardEvent) => {
      e.preventDefault();
      if (e.clipboardData) {
        const t = e.clipboardData.getData("text/plain");
        const selection = window.getSelection();
        if (selection) {
          const range = selection.getRangeAt(0);
          const node = document.createTextNode(t);
          range.insertNode(node);
          range.setStartAfter(node);
          range.setEndAfter(node);
          selection.removeAllRanges();
          selection.addRange(range);
          if (input.value) {
            const text = input.value.innerText;
            ctx.emit("update:modelValue", text);
          }
        }
      }
    };

    const focus = () => {
      focusIn.value = true;
      ctx.emit("focus");
    };

    const blur = () => {
      focusIn.value = false;
      ctx.emit("blur");
    };

    const keyPress = (e: KeyboardEvent) => {
      if (props.singleline) {
        if (e.key.toLowerCase() == "enter") {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    };

    const keyDown = (e: KeyboardEvent): void => {
      if (composition.value) return;
      if (e.key.toLowerCase() === "enter") {
        ctx.emit("enter");
      } else if (e.key.toLowerCase() === "escape") {
        ctx.emit("escape");
      }
    };

    onMounted(() => {
      updateValue();
    });

    return {
      input,
      composition,
      update,
      paste,
      focus,
      blur,
      keyPress,
      keyDown,
    };
  },
});
