
import { defineComponent } from "vue";

export default defineComponent({
  name: "Article",
  data() {
    return {
      title: "お知らせ",
      explainFirst: `最近のソフトディバイスでは、クライアントワークの傍ら、自社プロジェクトにも積極的に取り組んでいます。
今年はそのうちの２つを紹介させていただきます。

ご紹介するプロジェクトは、プロタイプ支援ツール 「EKAKIO(エカキオ)」 の開発です。
このEKAKIOは、既存のグラフィックツール（FigmaやXD、Powerpoint）に、スイッチやセンサーなどのハードウェアUIを連携動作させることが可能となるソフトウェアになります。しかもノーコードで制御ができるという驚きの手軽さになっています。
ご興味のある方は、こちらで詳しくご確認ください。
  <a href="https://ekakio.softdevice.jp" target="_blank">「EKAKIO(エカキオ)」</a>`,
      explainSecond: `２つ目のプロジェクトは、 認知症のある方のスケジュール管理を支援する日めくりカレンダーアプリ「yottey(ヨッティ)」の開発です。
認知症の初期段階から認められる、日付や曜日や時刻が分からなくなる 「時間見当識障害」でお困りの方を支援する目的で開発しています。タブレット、スマートフォン向けのアプリケーションで、Apple AppStore 、GooglePlay、Amazonアプリストアにて、現在期間限定にて無料で配信中です。
<a href="https://calendar-info.softdevice.co.jp/" target="_blank">「yottey(ヨッティ)」</a>`,
    };
  },
});
