import { InjectionKey, ref } from "vue";
import { UserData as RawUserData } from "@/data/interface";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const userData = (u: RawUserData) => {
  const data = ref(u);
  const me = ref(u.id === 99);

  const updateComment = (comment: string) => {
    if (comment === "") {
      data.value.comment = null;
      return;
    }
    data.value.comment = {
      text: comment,
      createdAt: new Date().toString(),
    };
  };

  const updateStatus = (emoji: string, description: string) => {
    data.value.status = {
      emoji,
      description,
      expiredAt: new Date().toString(),
    };
  };

  const deleteStatus = () => {
    data.value.status = null;
  };

  return {
    data,
    me,
    updateComment,
    updateStatus,
    deleteStatus,
  };
};

export type UserData = ReturnType<typeof userData>;
export const UserDataKey: InjectionKey<UserData> = Symbol("UserData");
