import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24f57c96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusBadge = _resolveComponent("StatusBadge")!
  const _component_StatusPalette = _resolveComponent("StatusPalette")!
  const _component_NextSchedule = _resolveComponent("NextSchedule")!
  const _component_Comment = _resolveComponent("Comment")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user", { active: _ctx.active }]),
    ref: "root",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkActive && _ctx.checkActive(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.checkInactive && _ctx.checkInactive(...args))),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.checkClickActive && _ctx.checkClickActive(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["plate", { active: _ctx.active, extend: _ctx.data.schedule }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "main",
          src: _ctx.data.profileImage
        }, null, 8, _hoisted_2),
        _createVNode(_component_StatusBadge, {
          class: _normalizeClass(["badge", { me: _ctx.me }]),
          active: _ctx.active,
          onClick: _ctx.openPelette
        }, null, 8, ["active", "class", "onClick"]),
        _createVNode(_component_StatusPalette, {
          class: "status-pallet",
          ref: "palette",
          active: _ctx.showStatusPalette,
          onSelect: _ctx.closePalette
        }, null, 8, ["active", "onSelect"])
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.data.displayName), 1),
      _createVNode(_component_NextSchedule, {
        class: "schedule",
        active: _ctx.active
      }, null, 8, ["active"])
    ], 2),
    _createVNode(_component_Comment, {
      class: _normalizeClass(["comment", { me: _ctx.me }]),
      active: _ctx.active
    }, null, 8, ["active", "class"])
  ], 34))
}