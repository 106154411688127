
import { defineComponent } from "vue";
import Header from "@/components/header.vue";
import UserPalette from "@/components/user-palette.vue";
import Description from "@/components/description.vue";
import Article from "@/components/article.vue";

export default defineComponent({
  name: "Activities",
  components: {
    Header,
    UserPalette,
    Description,
    Article,
  },
});
