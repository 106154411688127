
import { computed, defineComponent, inject, ref, watch } from "vue";
import { UserData, UserDataKey } from "./user-data";

export default defineComponent({
  name: "StatusBadge",
  props: {
    active: Boolean,
  },
  setup(props) {
    const { me, data } = inject(UserDataKey) as UserData;

    const statusData = ref(data.value.status);
    const message = ref<HTMLElement>();
    const messageWidth = ref(0);
    const containerStyle = computed(() => {
      return {
        width: messageWidth.value + "px",
      };
    });

    const updateMessageSize = () => {
      let w = 0;
      if (props.active) {
        if (message.value) {
          const r = message.value.getBoundingClientRect();
          w = r.width + 10;
          w = Math.min(w, 120);
        }
      }
      messageWidth.value = w;
    };

    watch(
      () => data.value.status,
      () => {
        statusData.value = null;
        setTimeout(() => {
          statusData.value = data.value.status;
          setTimeout(updateMessageSize);
        });
      }
    );

    watch(() => props.active, updateMessageSize);

    return {
      me,
      statusData,
      message,
      containerStyle,
    };
  },
});
