
import { defineComponent } from "vue";
import { userDataRepository } from "@/data/user";
import User from "@/components/user.vue";

export default defineComponent({
  name: "UserPalette",
  components: {
    User,
  },
  setup() {
    const { users } = userDataRepository();
    return { users };
  },
});
