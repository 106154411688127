export const firebaseConfig = {
  apiKey: "AIzaSyDXcxO6x6XaE4jkIMHgbrlp6SBvsjdk7-Y",
  authDomain: "softdevice-greeting.firebaseapp.com",
  databaseURL:
    "https://softdevice-greeting-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "softdevice-greeting",
  storageBucket: "softdevice-greeting.appspot.com",
  messagingSenderId: "702363966104",
  appId: "1:702363966104:web:d5cd4dccd9784b1949ceed",
  measurementId: "G-H7LX7X2FVJ",
};